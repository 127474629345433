<template>
  <div class="p-Message">
    <div class="header">
      <van-icon name="arrow-left" size="20" @click="$router.push({ name: 'user' })" />
      <center>消息通知</center>
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了">
        <section v-for="i in datas" :key="i.id">
          <h3 class="t">{{ i.title }}<span class="pull-right">{{ i.createTime | fromNow }}</span></h3>
          <p class="message" v-html="$options.filters.translation(i)"></p>
        </section>
      </van-list>
    </van-pull-refresh>
    <art-widget-logo style="padding-top: 20px; padding-bottom: 20px" />
  </div>
</template>

<script>
import request from '@/api/request'
import pullRefresh from '@/mixins/pull-refresh'

import store from '@/store'

export default {
  data () {
    return {
      asyncFetchData: request.getMessage
    }
  },
  mixins: [pullRefresh],
  filters: {
    translation (value) {
      const {
        content,
        msgType,
        msgParam = {}
      } = value || {}

      if (content === undefined) {
        return ''
      }

      const userInfo = store.getters.userInfo

      return content.replace(/\{(\w+)\}/, (str, matched) => {
        switch (msgType) {
          case 2: {
            if (matched && msgParam[matched]) {
              const to = `/user/${userInfo.id}/my-works`

              return `<a href="${to}">我的作品</a>`
            }
            break
          }
        }

        return str
      })
    }
  },
  created () {
    this.onLoad()
  }
}
</script>

<style lang="less">
.p-Message {
  min-height: 100vh;
  background-color: #F7F8FA;
  padding-bottom: @tabbar-height;

  section {
    padding: 0 20px 20px 20px;

    &:first-child {
      padding-top: 20px;
    }

    h3 {
      font-size: 16px;

      span {
        color: @text-grey;
        font-size: 12px;
        font-weight: normal;
        line-height: 24px;
      }
    }

    p {
      font-size: 13px;
      line-height: 1.7;
      word-break: break-word;
      margin-right: -.5em;
    }

    a {
      text-decoration: underline;
      padding: 0 2px;
    }
  }
}
</style>