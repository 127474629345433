var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-Setting-Phone"},[_c('div',{staticClass:"header"},[_c('van-icon',{attrs:{"name":"arrow-left","size":"20"},on:{"click":_vm.goBack}}),_c('center',[_vm._v("绑定手机")]),_c('div',{staticClass:"top-right--text"},[_c('a',{on:{"click":_vm.save}},[_vm._v("保存")])])],1),_c('van-cell-group',[_c('van-form',{ref:"form",on:{"submit":_vm.onSubmit}},[_c('van-field',{attrs:{"name":"oldmobile","label":"旧手机号","placeholder":"请填写旧手机号","rules":[
          { required: true, message: '请填写旧手机号' },
          { pattern: /^1[345789]\d{9}$/, message: '手机号码格式不正确' }
        ]},model:{value:(_vm.form.oldmobile),callback:function ($$v) {_vm.$set(_vm.form, "oldmobile", $$v)},expression:"form.oldmobile"}}),_c('van-field',{attrs:{"name":"newmobile","label":"新手机号","placeholder":"请填写新手机号","rules":[
          { required: true, message: '请填写新手机号' },
          { pattern: /^1[345789]\d{9}$/, message: '手机号码格式不正确' }
        ]},model:{value:(_vm.form.newmobile),callback:function ($$v) {_vm.$set(_vm.form, "newmobile", $$v)},expression:"form.newmobile"}}),_c('art-field-captcha',{attrs:{"label":"验证码","size":"normal"},on:{"start":_vm.getCaptcha},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }