<template>
  <div class="p-Traded">
    <div class="header">
      <van-icon name="arrow-left" size="20" @click="$router.push({ name: 'user' })" />
      <center>成交清单</center>
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了">
        <van-collapse v-model="collapse">
          <van-collapse-item
            v-for="i in datas"
            :name="i.orderCode"
            :key="i.orderCode"
          >
            <template #title>
              <span>{{ i.orderCode }}&nbsp;</span>
              <art-tag-contract-status :type="i.status" />
            </template>
            <p>藏家姓名：{{ i.collName }}</p>
            <p>藏家电话：{{ i.collPhone }}</p>
            <p>作品数量：{{ i.opusCount }}</p>
            <p>成交金额：{{ i.collTradeAmount }}
              <span
                v-if="i.opusCount > 0"
                class="pull-right"
                @click="handleClick(i.id)"
              >查看作品详情</span>
            </p>
          </van-collapse-item>
        </van-collapse>
      </van-list>
    </van-pull-refresh>
    <art-widget-logo style="padding-top: 20px; padding-bottom: 20px" />
    <van-overlay class="overlay-opusList" :show="opusListVisible">
      <div class="wrapper" @click.stop>
        <table>
          <tr>
            <th>作品编号</th>
            <th>作品名称</th>
            <th>作品信息</th>
            <th>市场参考价</th>
          </tr>
          <tr v-for="i in opusList" :key="i.opusId">
            <td>{{ i.opusCode }}</td>
            <td>{{ i.title }}</td>
            <td><p v-for="(row, $index) in i.meta" :key="$index">{{ row }}</p></td>
            <td>{{ i.suggestPrice | money }}</td>
          </tr>
        </table>
        <div class="clearfix">
          <van-button class="pull-right" type="info" native-type="button" @click="opusListVisible = false" plain size="small">确&nbsp;定</van-button>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import request from '@/api/request'
import pullRefresh from '@/mixins/pull-refresh'

import { OPUS_TYPE } from '@/config/constant'

export default {
  data () {
    return {
      collapse: [],
      opusListVisible: false,
      opusList: [],
      asyncFetchData: request.getContractList
    }
  },
  mixins: [pullRefresh],
  methods: {
    handleClick (id) {
      if (id !== undefined) {
        this.opusList = []
        request.getContract({ id }).then(({ opusList = [] }) => {
          this.opusList = opusList.map(o => {
            o.meta = [
              OPUS_TYPE[o.opusType],
              `${o.width}x${o.height}cm`,
              o.years
            ]

            return o
          })

          this.$nextTick(() => {
            this.opusListVisible = true
          })
        })
      }
    }
  },
  created () {
    this.onLoad()
  }
}
</script>

<style lang="less">
.p-Traded {
  min-height: 100vh;
  background-color: #F7F8FA;
  padding-bottom: @tabbar-height;

  .van-collapse-item__content {
    padding-bottom: 8px;

    p {
      margin-bottom: 8px;
    }
  }

  .overlay-opusList {

    table {
      width: 100%;
      margin-bottom: 16px;
      text-align: center;

      th {
        font-size: 13px;
        white-space: nowrap;
        background: #fafafa;
        padding: 12px 2px;
      }

      td {
        font-size: 12px;
        word-break: break-all;
        padding: 6px 2px;

        p {
          line-height: 1.7;
          margin-bottom: 0;
        }
      }

      th, td {
        border-bottom: 1px solid #ebedf0;
      }
    }
  }
}
</style>