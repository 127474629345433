<template>
  <div class="p-Uploaded">
    <van-sticky class="sticky-header">
      <van-icon name="arrow-left" size="20" @click="clickOnBack" />
      <center>上传作品<span>({{ step.index }}/{{ step.total }})</span></center>
    </van-sticky>
    <transition name="van-fade" mode="out-in">
      <div class="step-1" v-if="step.index === 1" key="1">
        <van-form @submit="submitStep1">
          <van-cell-group>
            <van-cell v-if="filters.thumbnailPath !== undefined" class="upload" title="作品封面图" required>
              <template #label>
                <art-widget-avatar
                  v-model="filters.thumbnailPath"
                  width="80"
                  height="80"
                  oss-type="opus"
                  :round="false"
                />
              </template>
            </van-cell>
            <van-cell v-if="filters.effectDrawingPaths !== undefined" class="upload" title="作品细节图" required>
              <template #default>
                <span>{{ filters.effectDrawingPaths.length }}/{{ maxCount }}</span>
              </template>
              <template #label>
                <art-widget-file-list
                  v-model="filters.effectDrawingPaths"
                  oss-type="opus"
                  :max-count="maxCount"
                />
              </template>
            </van-cell>
            <van-field
              v-model="filters.title"
              placeholder="作品名称"
              label="作品名称"
              :rules="[
                { required: true, message: '作品名称必填' }
              ]"
              required
            />
          </van-cell-group>
          <van-cell-group title="作品尺寸">
            <van-field
              v-model="filters.width"
              placeholder="单位cm"
              label="宽度"
              :rules="[
                { required: true, message: '宽度必填' }
              ]"
              required
            />
            <van-field
              v-model="filters.height"
              placeholder="单位cm"
              label="高度"
              :rules="[
                { required: true, message: '高度必填' }
              ]"
              required
            />
          </van-cell-group>
          <van-button type="info" block>下一步</van-button>
        </van-form>
      </div>
      <div class="step-2" v-if="step.index === 2" key="2">
        <van-form @submit="submitStep2">
          <van-cell-group>
            <template
              v-for="(o, $index) in [
                {
                  name: '作品类型',
                  key: 'opusType',
                  required: true
                },
                {
                  name: '色系',
                  key: 'colorType',
                  required: true
                }
              ]"
            >
              <div v-if="filters[o.key]" :key="$index">
                <van-cell
                  :title="o.name"
                  :required="o.required"
                >
                  <template #label>
                    <art-radio-group
                      v-model="filters[o.key].value"
                      :options="filters[o.key].options"
                      :span="8"
                      :gutter="10"
                      :multiple="$index > 0"
                    />
                  </template>
                </van-cell>
              </div>
            </template>
            <van-field
              v-model="filters.years"
              placeholder="作品年代"
              label="作品年代"
              :rules="[
                { required: true, message: '作品年代必填' }
              ]"
              required
            />
            <van-cell v-if="filters.opusNote !== undefined" title="创作手记" required>
              <template #label>
                <van-field
                  v-model="filters.opusNote"
                  rows="4"
                  type="textarea"
                  maxlength="500"
                  :rules="[
                    { required: true, message: '创作手记必填' },
                    { validator: value => {
                      return /[\s\S]{20,500}/.test(value.replace(/^\s+|\s+$/gm,''))
                    }, message: '创作手记需要20-500字' }
                  ]"
                  placeholder="分享你的创作灵感、历程等经历"
                  show-word-limit
                />
              </template>
            </van-cell>
            <van-field
              v-model="filters.price"
              placeholder="最终价格将与平台商定"
              label="作品价格"
              :rules="[
                { required: true, message: '作品价格必填' }
              ]"
              required
            />
            <van-cell title="是否公开显示">
              <template #default>
                <van-switch v-model="filters.openSearch" size="20px" />
              </template>
            </van-cell>
          </van-cell-group>
          <center style="margin-bottom: 24px;">
            <a-checkbox @change="agreed = !agreed">阅读并同意<a @click.prevent="$overlay.agreement(2)">《服务条款》</a></a-checkbox>
          </center>
          <van-button type="info" :disabled="!agreed" :loading="loading" block>提交作品</van-button>
        </van-form>
      </div>
    </transition>
  </div>
</template>

<script>
import store from '@/store'
import request from '@/api/request'
import utils from '@/utils'

import {
  OPUS_TYPE
} from '@/config/constant'

const opusTypeMap = {}

for (let key in OPUS_TYPE) {
  opusTypeMap[OPUS_TYPE[key]] = key
}

export default {
  data () {
    return {
      step: {
        index: 1,
        total: 2
      },
      maxCount: 6,
      filters: {},
      agreed: false,
      loading: false
    }
  },
  methods: {
    clickOnBack () {
      if (this.step.index === 1) {
        if (utils.isEqual(this.filters, this.initialFilters)) {
          return this.$router.push({ name: 'user' })
        }

        return this.$dialog.confirm({
          message: '确定是否退出，当前内容不会被保存'
        })
          .then(() => {
            this.$router.push({ name: 'user' })
          })
          .catch(() => {})
      }

      this.step.index = 1;
    },
    getValues () {
      const {
        options,
        ...filters
      } = utils.cloneDeep(this.filters)

      // TODO: options no use
      for (let o in options) {
        const { key, value } = options[o]

        if (key) {
          filters[key] = value
        }
      }

      for (let key in filters) {
        let value = filters[key]

        if (value && value.value !== undefined) {
          value = value.value
        }

        if (
          key !== 'effectDrawingPaths' &&
          Array.isArray(value)
        ) {
          value = value
            .map(o => {
              if (key === 'opusType') {
                return opusTypeMap[o.name]
              }

              return o.name || o
            })
            .join(',')
        }

        // convert number
        const toNumberKeys = [
          'opusType',
          'height',
          'width',
          'openSearch'
        ]

        if (~toNumberKeys.indexOf(key)) {
          value = Number(value)
        }

        filters[key] = value
      }

      return filters
    },
    submitStep1 () {
      const values = this.getValues()

      if (values.thumbnailPath === null) {
        return this.$notify({
          type: 'warning',
          message: '作品封面图不可为空'
        })
      }

      if (
        !Array.isArray(values.effectDrawingPaths) ||
        (values.effectDrawingPaths.length === 0)
      ) {
        return this.$notify({
          type: 'warning',
          message: '作品细节图不可为空'
        })
      }

      this.step.index = 2;
    },
    submitStep2 () {
      const values = this.getValues()

      if (values.opusType === '') {
        return this.$notify({
          type: 'warning',
          message: '作品类型必选'
        })
      }

      if (values.colorType === '') {
        return this.$notify({
          type: 'warning',
          message: '色系必选'
        })
      }

      const opusId = this.$route.params.opusId

      if (opusId) {
        values.opusId = opusId
      }

      this.loading = true

      request.uploadOpus(values)
        .then(() => {
          this.loading = false
          this.$notify({
            type: 'success',
            message: '作品提交成功'
          })

          this.$nextTick(() => {
            this.$router.push({ name: 'user' })
          })
        })
        .catch(() => {
          this.loading = false
        })
    },
    fetchData () {
      const opusId = this.$route.params.opusId

      if (opusId) {
        request.getUploadOpus({ opusId })
          .then(({ opus = {}} = {}) => {
            const filters = {}
            const {
              thumbnailPath = '',
              thumbnailPathFull = '',
              effectDrawingPaths = [],
              effectDrawingPathsFull = [],
              opusType,
              openSearch,
              ...others
            } = opus

            filters.thumbnailPath = thumbnailPathFull || thumbnailPath
            filters.effectDrawingPaths = effectDrawingPathsFull || effectDrawingPaths
            filters.openSearch = Boolean(openSearch)

            if (OPUS_TYPE[opusType]) {
              others.opusType = OPUS_TYPE[opusType]
            }

            for (const key in this.filters) {
              if (others[key] !== undefined) {
                const {
                  value,
                  options
                } = this.filters[key] || {}

                if (value !== undefined) {
                  others[key] = { options, value: others[key].split(',') }
                }

                filters[key] = others[key]
              }
            }

            this.filters = utils.assign(this.filters, filters)
          })
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('GetTags', tags => {
      return next(vm => {
        // add initial value
        const {
          colorType
        } = tags

        tags = { colorType }

        for (const o in tags) {
          tags[o].value = tags[o].value || []
        }

        vm.initialFilters = {
          title: '',
          opusType: {
            options: Object.values(OPUS_TYPE),
            value: []
          },
          width: null,
          height: null,
          openSearch: true,
          opusNote: '',
          price: null,
          effectDrawingPaths: [],
          thumbnailPath: null,
          years: '',
          ...tags
        }

        // set filter before Enter
        vm.filters = utils.cloneDeep(vm.initialFilters)

        if (to.params.opusId) {
          vm.fetchData.apply(vm)
        }
      })
    })
  }
}
</script>

<style lang="less">
.p-Uploaded {
  min-height: 100vh;

  .van-form {
    margin-bottom: 30px;
    
    .van-cell {
      .van-cell__title > span {
        font-weight: 500;
      }
    }
    .van-cell__label {
      margin-top: 10px;
    }
    
  }

  .step-1,
  .step-2 {
    padding: 0 20px @tabbar-height;

    // .RadioGroup {
    //   margin-bottom: -10px;
    // }

    .van-cell,
    .van-cell-group__title {
      padding-left: 0;
      padding-right: 0;
    }

    .van-cell:not(:last-child)::after {
      left: 0;
    }

    .van-cell--required::before {
      left: -8px;
    }

    .van-cell-group {
      margin-bottom: 24px;
    }

    & > .van-button {
      margin-bottom: 24px;
    }
  }

  .upload {

    .van-cell__value {
      flex: initial;
    }

    .Avatar {
      width: 80px;
    }
  }
}
</style>