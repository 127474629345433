<template>
  <div class="p-MyWorks">
    <van-sticky class="sticky-header">
      <van-icon name="arrow-left" size="20" @click="$router.push({ name: 'user' })" />
      <center>我的作品</center>
      <span>共{{ totalCount }}个作品</span>
      <form action="/">
        <van-search
          v-model="query"
          shape="round"
          placeholder="请输入作品名搜索我的作品"
          @search="reset"
          @cancel="reset"
        />
      </form>
    </van-sticky>
    <div class="context" v-if="datas.length">
      <van-row gutter="15">
        <van-col
          v-for="i in datas"
          @click="handleClick(i)"
          span="12"
          :key="i.opusId"
        >
          <art-card-art
            :name="i.opusTitle"
            width="100%"
            height="120"
            :src="i.imgUrl"
          >
            <span>{{ `${i.opusType} / ${i.width}x${i.height}cm / ${i.years}` }}</span>
          </art-card-art>
          <art-tag-art-verify :type="i.verfyStatus" />
        </van-col>
      </van-row>
      <van-pagination v-model="page" :page-count="pageCount" mode="simple" @change="fetchData" />
    </div>
    <van-empty v-else description="暂无作品" />
  </div>
</template>

<script>
import request from '@/api/request'
import utils from '@/utils'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      query: '',
      // pager
      datas: [],
      page: 1,
      pageSize: 10,
      pageCount: 0,
      totalCount: 0
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  methods: {
    reset () {
      this.page = 1
      this.fetchData()
    },
    fetchData () {
      request.getMyArtList({
        title: this.query,
        currPage: this.page,
        pageSize: this.pageSize
      }).then(res => {
        const {
          totalCount,
          totalPage,
          list = []
        } = res

        this.totalCount = totalCount
        this.pageCount = totalPage
        this.datas = list

        utils.scrollTo(0)
      })
    },
    handleClick (record) {
      const {
        verfyStatus,
        opusId
      } = record || {}

      if (opusId === undefined) {
        return
      }

      if (verfyStatus === 1) {
        return this.$router.push({
          name: 'art',
          params: { id: opusId }
        })
      }

      this.$router.push({
        name: 'uploadedId',
        params: {
          id: this.userInfo.id,
          opusId
        }
      })
    }
  },
  created () {
    this.fetchData()
  }
}
</script>

<style lang="less">
.p-MyWorks {
  min-height: 100vh;
  background-color: #F7F8FA;
  padding-bottom: @tabbar-height;

  .CardArt .van-image+div {
    padding: 10px 0 0;
  }

  .sticky-header {
    position: relative;
    height: initial;

    .van-sticky {
      padding-bottom: 0;

      & > .van-icon {
        top: 20px;
        transform: initial;
      }
    }

    center + span {
      position: absolute;
      right: 16px;
      top: 20px;
      font-size: 12px;
      line-height: 20px;
      color: #888;
    }
  }

  .van-search {
    padding: 10px 0;
  }

  .context {
    padding: 20px;
    background-color: #fff;

    .van-pagination {
      margin-bottom: 0;
    }

    .van-col {
      position: relative;
    }

    .CardArt {
      margin-bottom: 20px;
      display: block;

      .van-image {
        border-radius: 6px;
        overflow: hidden;
      }

      .van-multi-ellipsis--l2 {
        height: 36px;
      }
    }

    .tag-ArtVerify {
      position: absolute;
      top: 8px;
      right: 15px;
      z-index: 1;
    }
  }
}
</style>