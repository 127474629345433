var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-Setting-Password"},[_c('div',{staticClass:"header"},[_c('van-icon',{attrs:{"name":"arrow-left","size":"20"},on:{"click":_vm.goBack}}),_c('center',[_vm._v("更改账号密码")]),_c('div',{staticClass:"top-right--text"},[_c('a',{on:{"click":_vm.save}},[_vm._v("保存")])])],1),_c('van-cell-group',[_c('van-form',{ref:"form",on:{"submit":_vm.onSubmit}},[_c('van-field',{attrs:{"type":"password","name":"oldpassword","label":"旧密码","placeholder":"请输入旧密码","rules":[
          { required: true, message: '请填写旧密码' }
        ]},model:{value:(_vm.form.oldpassword),callback:function ($$v) {_vm.$set(_vm.form, "oldpassword", $$v)},expression:"form.oldpassword"}}),_c('van-field',{attrs:{"type":"password","name":"newpassword","label":"新密码","placeholder":"请输入新密码","rules":[
          { required: true, message: '请填写新密码' },
          { pattern: /\S{6}/, message: '新密码过短' },
        ]},model:{value:(_vm.form.newpassword),callback:function ($$v) {_vm.$set(_vm.form, "newpassword", $$v)},expression:"form.newpassword"}}),_c('van-field',{attrs:{"type":"password","label":"再次新密码","placeholder":"请再次输入新密码","rules":[
          { required: true, message: '请再次填写新密码' },
          { validator: val => this.form.newpassword === val, message: '两次密码不匹配' }
        ]},model:{value:(_vm.form.confirm),callback:function ($$v) {_vm.$set(_vm.form, "confirm", $$v)},expression:"form.confirm"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }