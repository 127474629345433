import { ImagePreview } from 'vant'
import request from '@/api/request'
import utils from '@/utils'
import pullRefresh from '@/mixins/pull-refresh'

import {
  RESUME_GROUP_DELIMITER,
  RESUME_DELIMITER
} from '@/views/user/id/Resume'

const preview = url => {
  if (url !== undefined) {
    ImagePreview({
      images: [url],
      startPosition: 0,
      closeable: true,
    })
  }
}

export const Art = {
  data () {
    return {
      asyncFetchData: request.getArtListByUserId,
      getParams: () => ({
        userId: this.$route.params.id
      })
    }
  },
  mixins: [pullRefresh],
  render (h) {
    let list = this.datas.map(({
      opusId,
      opusTitle,
      imgUrl,
      opusType,
      width,
      height,
      years
    }) => {
      const subTitle =  [
        opusType,
        `${width}x${height}cm` // TODO: 0
      ]

      if (years > 0) {
        subTitle.push(`${years}`)
      }

      return h('van-col', {
        props: { span: 12 },
        on: {
          click: () => {
            this.$router.push({
              name: 'art',
              params: { id: opusId }
            })
          }
        }
      }, [
        h('art-card-art', {
          props: {
            src: imgUrl,
            width: '100%',
            height: 120,
            name: opusTitle
          }
        }, [
          h('span', subTitle.join(' / '))
        ])
      ])
    })

    if (list.length) {
      list = [
        h('van-row', {
          props: { gutter: 20 }
        }, list)
      ]
    } else {
      if (this.inited) {
        return h('van-empty', { props: { description: '艺术家作品为空' } })
      }
    }

    const wrapper = h('van-list', {
      props: {
        value: this.loading,
        finished: this.finished,
        finishedText: '没有更多了'
      },
      on: {
        input: val => this.loading = val,
        load: evt => this.onLoad(evt)
      }
    }, list)

    return h('a-spin', {
      props: {
        spinning: this.busy
      }
    }, [
      h('van-pull-refresh', {
        class: ['p-tab-Art'],
        props: {
          value: this.refreshing,
          disabled: true // disable pull refresh
        },
        on: {
          input: val => this.refreshing = val,
          refresh: evt => this.onRefresh(evt)
        }
      }, [wrapper])
    ])
  }
}

export const Resume = {
  data () {
    return {
      form: {}
    }
  },
  render (h) {
    const children = []
    const isNotEmpty = s => !utils.isEmpty(s)

    let isPageEmpty = true

    if (isNotEmpty(this.form.introduction)) {
      children.push(
        h('div', {
          class: ['block']
        }, [
          h('h3', '个人简介'),
          h('p', this.form.introduction)
        ])
      )
      isPageEmpty = false
    }

    if (isNotEmpty(this.form.displayHistory)) {
      children.push(
        h('div', {
          class: ['block']
        }, [
          h('h3', '展览经历'),
          h('a-timeline', this.form.displayHistory.map(
            ([m, t]) => h('a-timeline-item', [
              h('span', m),
              h('p', t)
            ])
          ))
        ])
      )
      isPageEmpty = false
    }

    if (isNotEmpty(this.form.certificateHistory)) {
      children.push(
        h('div', {
          class: ['block']
        }, [
          h('h3', '获奖证书'),
          h('van-swipe', {
            props: {
              loop: false,
              showIndicators: false,
              width: 100 + 20
            }
          }, this.form.certificateHistory.map(
            imgUrl => h('van-swipe-item', [
              h('van-image', {
                props: {
                  width: 100,
                  height: 100,
                  src: imgUrl || '',
                  fit: 'cover'
                },
                on: {
                  click: () => preview(imgUrl)
                }
              })
            ])
          ))
        ])
      )
      isPageEmpty = false
    }

    if (isNotEmpty(this.form.publishHistory)) {
      const innerHTML = this.form.publishHistory
        .reduce((value, [text, img]) => {
          let cloneValue = value

          if (text) {
            cloneValue += `<p>${text}</p>`
          }
          if (img) {
            cloneValue += `<img src="${img}">`
          }

          if (cloneValue !== value) {
            cloneValue += '<br>'
          }

          return cloneValue
        }, '')

      children.push(
        h('div', {
          class: ['block']
        }, [
          h('h3', '出版经历'),
          h('div', {
            directives: [
              {
                name: 'filter-html',
                value: innerHTML
              }
            ]
          })
        ])
      )
      isPageEmpty = false
    }

    return isPageEmpty
      ? h('van-empty', { props: { description: '艺术家履历为空' } })
      : h('div', {
        class: ['p-tab-Resume']
      }, children)
  },
  mounted() {

    let UserInfo = localStorage.getItem('userInfo');
    let userId = this.$route.params.id?this.$route.params.id:JSON.parse(UserInfo).id
    request.getResumeByUserId({
      // userId: this.$route.params.id
      userId: userId
    }).then(res => {
      let { details: form = {} } = res
      const keys = Object.keys(form)
      const isNotEmpty = s => s !== ''

       for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i]
        let formValue = form[key]

        switch (key) {
          case 'displayHistory':
          case 'publishHistory': {
            formValue = formValue
              .split(RESUME_GROUP_DELIMITER)
              .filter(isNotEmpty)

            for (let j = 0; j < formValue.length; j += 1) {
              formValue[j] = formValue[j]
                .split(RESUME_DELIMITER)
                .filter(isNotEmpty)
            }

            break
          }
          case 'certificateHistory': {
            formValue = formValue
              .split(RESUME_GROUP_DELIMITER)
              .filter(isNotEmpty)

            break
          }
        }

        form[key] = formValue
      }

      this.form = form
    })
  }
}
