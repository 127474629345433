<template>
  <div class="p-UserPage">
    <div class="top-left--btn">
      <van-icon class-prefix="icon-artalliance" name="left" @click="$router.push({ name: 'user' })" />
    </div>
    <div class="top-right--btn">
      <van-icon class-prefix="icon-artalliance" name="share" @click="shareVisible = true" />
      <art-widget-share v-model="shareVisible" />
    </div>
    <a-spin :spinning="loading">
      <div class="square">
        <div class="square-in">
          <center>
            <van-image
              round
              width="100px"
              height="100px"
              :src="userInfo.avatar === DEFAULT_AVATAR ? DEFAULT_AVATAR : userInfo.avatar + '!avatar_120'"
            />
            <h3>{{ userInfo.displayName }}</h3>
            <art-tag-role :type="userInfo.roleInfo" />
            <blockquote>{{ userInfo.userSay }}</blockquote>
          </center>
        </div>
        <div class="square-bg">
          <van-image
            width="100%"
            height="100%"
            :show-loading="false"
            fit="cover"
            :src="userInfo.backgroundUrl === DEFAULT_BACKGROUND ? DEFAULT_BACKGROUND : userInfo.backgroundUrl + '!profile_background'"
          />
        </div>
      </div>
      <van-tabs
        v-model="active"
        @change="onChange"
        line-width="60"
        line-height="6"
      >
        <van-tab
          v-for="({ name, title }) in tabs"
          :title="title"
          :name="name"
          :key="name"
        >
          <component :is="name"></component>
          <div v-if="userInfo.roleInfo==1" v-on:click="userupload()" style="text-align: center;font-size: 48px;margin-top: -10px;color: #b4b4b4;">+</div>
        </van-tab>
      </van-tabs>
    </a-spin>
    <art-widget-share v-model="shareVisible" :poster-option="posterOption" />
    <!-- <art-poster-artist
      :avatar="t.avatar"
      :imageUrl="t.imageUrl"
      :avatarName="t.avatarName"
      :subTitle="t.subTitle"
      :qrUrl="t.qrUrl"
    /> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import request, { getQr } from '@/api/request'
import { Art, Resume } from './tab-components'
import { DEFAULT_AVATAR, DEFAULT_BACKGROUND } from '@/config/constant'
import utils from '@/utils'

export default {
  data () {
    const tabs = [
      { title: 'Ta的作品', name: 'art' },
      { title: '艺术履历', name: 'resume' }
    ]

    const posterOption = {
      name: '用户名片',
      icon: 'qrcode',
      posterComponent: 'art-poster-artist',
      beforeCreatePoster: () => new Promise((resolve) => {
        const {
          avatar,
          backgroundUrl,
          displayName,
          introduction
        } = this.userInfo || {}

        const props = {
          avatar,
          imageUrl: backgroundUrl,
          avatarName: displayName,
          subTitle: introduction || '认证艺术家'
        }

        const qrUrl = getQr({
          code: document.location.origin + this.$route.fullPath,
          width: 80,
          height: 80
        })

        resolve({
          qrUrl,
          title: displayName,
          ...props
        })
      })
    }

    return {
      active: tabs[0].name,
      tabs,
      loading: true,
      userInfo: {},
      isMyself: false,
      shareVisible: false,
      posterOption,
      DEFAULT_AVATAR,
      DEFAULT_BACKGROUND
      // t: {
      //   qrUrl: '/api/app/qr?code=http:%2F%2F192.168.50.102:8080%2F…62694273098981376%3Ftype%3Dart&width=80&height=80',
      //   title: '艺友BTOL',
      //   avatar: 'https://oss.resource.byzx.art/avatar/62694273098981376/202007/srhol5xh59n.jpg',
      //   imageUrl: 'https://oss.resource.byzx.art/background/62694273098981376/202007/fauwx0m2et7.png',
      //   avatarName: '艺友BTOL',
      //   subTitle: '认证艺术家'
      // }
    };
  },
  computed: {
    ...mapGetters([
      'logined'
    ]),
    ...mapGetters({
      myUserInfo: 'userInfo'
    })
  },
  methods: {
    onChange (name) {
      this.$router.push({
        name: this.$route.name,
        query: { type: name }
      })
    },
    userupload:function(){
      console.log(JSON.stringify(this.userInfo));
      this.$router.push({
        name: 'userupload'
      })
    }
  },
  components: { Art, Resume },
  created () {
    const makeUpDefaultImage = () => {
      const {
        avatar,
        backgroundUrl
      } = this.userInfo

      if (utils.isEmpty(avatar)) {
        this.userInfo.avatar = DEFAULT_AVATAR
      }

      if (utils.isEmpty(backgroundUrl)) {
        this.userInfo.backgroundUrl = DEFAULT_BACKGROUND
      }
    }
    const init = ({ id: myId }) => {
      const id = this.$route.params.id

      this.isMyself = id === myId
      this.loading = false

      if (this.isMyself) {
        this.userInfo = utils.cloneDeep(this.myUserInfo)
        this.tabs[0].title = '我的作品'
        makeUpDefaultImage()
      } else {
        request.getInfoByUserId({
          userId: id
        }).then(res => {
          this.userInfo = (res && res.user) || {}
          makeUpDefaultImage()
        })
      }
    }

    if (!this.logined) {
      this.$store.dispatch('GetInfo', init)
    } else {
      init(this.myUserInfo)
    }

    const active = this.$route.query.type

    if (active === undefined) {
      this.$router.replace({
        name: this.$route.name,
        query: Object.assign({}, this.$route.query, {
          type: this.active
        })
      })
    }
    else if (active !== this.active) {
      this.active = active
    }
  }
}
</script>

<style lang="less">
.p-UserPage {
  min-height: 100vh;
  padding-bottom: @tabbar-height;
  background-color: #F7F8FA;

  .header {
    position: absolute;
    width: 100%;
    z-index: 99;
    background-color: initial;
    color: #fff;
  }

  .square {
    .tag-Role {
      font-size: 13px;
    }
    blockquote {
      font-size: 14px;
      line-height: 22px;
      max-height: 66px;
      overflow: hidden;
      width: 72%;
      margin: 0;
    }
    .square-in
      h3 {
        width: 100%;
      }
    .icon-artalliance {
      font-size: 18px;
    }
    .square-bg {
      background-color: rgba(0,0,0,.2);
    }
  }
    

  .van-tabs {
    background-color: #fff;
  }

  .van-tabs__wrap {
    height: 48px;
    width: 60%;
    margin: 0 auto;

    &:after {
      content: none;
    }

    .van-tab {
      line-height: 48px;
    }
  }

  .van-tabs__content {
    padding-top: 4px;
    background-color: #F7F8FA;
  }

  .van-tabs__line {
    bottom: 50%;
    margin-bottom: -2px;
    background-color: #B4B4B4;
    border-radius: 0 !important;
    opacity: .5;
  }

  .p-tab-Art {
    background-color: #fff;

    .van-row {
      padding: 20px 20px 0;
    }

    .CardArt {

      .van-image {
        border-radius: 6px;
        overflow: hidden;
      }

      .van-multi-ellipsis--l2 {
        // height: 36px;
        height: 18px;
      }

      .van-image + div {
        padding: 10px 0 0;
      }
    }
  }

  .p-tab-Resume {

    .block {
      background-color: #fff;
      padding: 20px;
      margin-bottom: 5px;

      h3 {
        font-size: 16px;
        color: #040404;
        margin-bottom: 15px;
      }
      p {
        font-size: 14px;
        color: #454647;
        line-height: 1.4;
        margin-bottom: 10px;
      }
    }

    .ant-timeline {
      padding: 8px 12px;
    }

    .ant-timeline-item {

      span {
        color: #B4B4B4;
        font-size: 12px;
        margin-bottom: 5px;
        display: inline-block;
      }

      p {
        font-size: 14px;
        color: #454647;
        line-height: 1.4;
      }
    }

    .ant-timeline-item-content {
      margin: 0 0 0 25px;
    }

    .ant-timeline-item-head-blue {
      height: 12px;
      width: 12px;
      color: #B7DFFF;
      border-color: #B7DFFF;
    }

    .ant-timeline-item-tail {
      border-left: 4px solid #F7F8FA;
    }
  }

  .x-filter-html {

    img {
      max-width: 100%;
    }
  }
}
</style>