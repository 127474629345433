<template>
  <div class="p-Favorites">
    <van-sticky class="sticky-header">
      <van-icon name="arrow-left" size="20" @click="$router.push({ name: 'user' })" />
      <center>我的收藏</center>
      <span>共{{ totalCount }}个收藏</span>
      <form action="/">
        <van-search
          v-model="query"
          shape="round"
          placeholder="请输入作品名搜索我的作品"
          @search="reset"
          @cancel="reset"
        />
      </form>
    </van-sticky>
    <div class="context" v-if="datas.length">
      <van-row gutter="15">
        <van-col
          v-for="i in datas"
          @click="$router.push({ name: 'art', params: { id: i.opusId }})"
          span="12"
          :key="i.opusId"
        >
          <art-card-art
            :name="i.opusTitle"
            width="100%"
            height="120"
            :src="i.imgUrl"
          >
            <span>{{ `${i.opusType} / ${i.width}x${i.height}cm / ${i.years}` }}</span>
          </art-card-art>
        </van-col>
      </van-row>
      <van-pagination v-model="page" :page-count="pageCount" mode="simple" @change="fetchData" />
    </div>
    <van-empty v-else description="暂无收藏" />
  </div>
</template>

<script>
import request from '@/api/request'
import utils from '@/utils'

export default {
  data () {
    return {
      query: '',
      // pager
      datas: [],
      page: 1,
      pageSize: 10,
      pageCount: 0,
      totalCount: 0
    }
  },
  methods: {
    reset () {
      this.page = 1
      this.fetchData()
    },
    fetchData () {
      request.getMyFavorite({
        title: this.query,
        currPage: this.page,
        pageSize: this.pageSize
      }).then(res => {
        const {
          totalCount,
          totalPage,
          list = []
        } = res

        this.totalCount = totalCount
        this.pageCount = totalPage
        this.datas = list

        utils.scrollTo(0)
      })
    }
  },
  created () {
    this.fetchData()
  }
}
</script>

<style lang="less">
.p-Favorites {
  min-height: 100vh;
  background-color: #F7F8FA;
  padding-bottom: @tabbar-height;

  .CardArt .van-image+div {
    padding: 10px 0 0;
  }

  .sticky-header {
    position: relative;
    height: initial;

    .van-sticky {
      padding-bottom: 0;

      & > .van-icon {
        top: 20px;
        transform: initial;
      }
    }

    center + span {
      position: absolute;
      right: 15px;
      top: 20px;
      font-size: 12px;
      line-height: 20px;
      color: #888;
    }
  }

  .van-search {
    padding: 10px 0;
  }

  .context {
    padding: 20px;
    background-color: #fff;
  }
}
</style>