var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-Uploaded"},[_c('van-sticky',{staticClass:"sticky-header"},[_c('van-icon',{attrs:{"name":"arrow-left","size":"20"},on:{"click":_vm.clickOnBack}}),_c('center',[_vm._v("上传作品"),_c('span',[_vm._v("("+_vm._s(_vm.step.index)+"/"+_vm._s(_vm.step.total)+")")])])],1),_c('transition',{attrs:{"name":"van-fade","mode":"out-in"}},[(_vm.step.index === 1)?_c('div',{key:"1",staticClass:"step-1"},[_c('van-form',{on:{"submit":_vm.submitStep1}},[_c('van-cell-group',[(_vm.filters.thumbnailPath !== undefined)?_c('van-cell',{staticClass:"upload",attrs:{"title":"作品封面图","required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('art-widget-avatar',{attrs:{"width":"80","height":"80","oss-type":"opus","round":false},model:{value:(_vm.filters.thumbnailPath),callback:function ($$v) {_vm.$set(_vm.filters, "thumbnailPath", $$v)},expression:"filters.thumbnailPath"}})]},proxy:true}],null,false,1658979279)}):_vm._e(),(_vm.filters.effectDrawingPaths !== undefined)?_c('van-cell',{staticClass:"upload",attrs:{"title":"作品细节图","required":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.filters.effectDrawingPaths.length)+"/"+_vm._s(_vm.maxCount))])]},proxy:true},{key:"label",fn:function(){return [_c('art-widget-file-list',{attrs:{"oss-type":"opus","max-count":_vm.maxCount},model:{value:(_vm.filters.effectDrawingPaths),callback:function ($$v) {_vm.$set(_vm.filters, "effectDrawingPaths", $$v)},expression:"filters.effectDrawingPaths"}})]},proxy:true}],null,false,3868821742)}):_vm._e(),_c('van-field',{attrs:{"placeholder":"作品名称","label":"作品名称","rules":[
              { required: true, message: '作品名称必填' }
            ],"required":""},model:{value:(_vm.filters.title),callback:function ($$v) {_vm.$set(_vm.filters, "title", $$v)},expression:"filters.title"}})],1),_c('van-cell-group',{attrs:{"title":"作品尺寸"}},[_c('van-field',{attrs:{"placeholder":"单位cm","label":"宽度","rules":[
              { required: true, message: '宽度必填' }
            ],"required":""},model:{value:(_vm.filters.width),callback:function ($$v) {_vm.$set(_vm.filters, "width", $$v)},expression:"filters.width"}}),_c('van-field',{attrs:{"placeholder":"单位cm","label":"高度","rules":[
              { required: true, message: '高度必填' }
            ],"required":""},model:{value:(_vm.filters.height),callback:function ($$v) {_vm.$set(_vm.filters, "height", $$v)},expression:"filters.height"}})],1),_c('van-button',{attrs:{"type":"info","block":""}},[_vm._v("下一步")])],1)],1):_vm._e(),(_vm.step.index === 2)?_c('div',{key:"2",staticClass:"step-2"},[_c('van-form',{on:{"submit":_vm.submitStep2}},[_c('van-cell-group',[_vm._l(([
              {
                name: '作品类型',
                key: 'opusType',
                required: true
              },
              {
                name: '色系',
                key: 'colorType',
                required: true
              }
            ]),function(o,$index){return [(_vm.filters[o.key])?_c('div',{key:$index},[_c('van-cell',{attrs:{"title":o.name,"required":o.required},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('art-radio-group',{attrs:{"options":_vm.filters[o.key].options,"span":8,"gutter":10,"multiple":$index > 0},model:{value:(_vm.filters[o.key].value),callback:function ($$v) {_vm.$set(_vm.filters[o.key], "value", $$v)},expression:"filters[o.key].value"}})]},proxy:true}],null,true)})],1):_vm._e()]}),_c('van-field',{attrs:{"placeholder":"作品年代","label":"作品年代","rules":[
              { required: true, message: '作品年代必填' }
            ],"required":""},model:{value:(_vm.filters.years),callback:function ($$v) {_vm.$set(_vm.filters, "years", $$v)},expression:"filters.years"}}),(_vm.filters.opusNote !== undefined)?_c('van-cell',{attrs:{"title":"创作手记","required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('van-field',{attrs:{"rows":"4","type":"textarea","maxlength":"500","rules":[
                  { required: true, message: '创作手记必填' },
                  { validator: value => {
                    return /[\s\S]{20,500}/.test(value.replace(/^\s+|\s+$/gm,''))
                  }, message: '创作手记需要20-500字' }
                ],"placeholder":"分享你的创作灵感、历程等经历","show-word-limit":""},model:{value:(_vm.filters.opusNote),callback:function ($$v) {_vm.$set(_vm.filters, "opusNote", $$v)},expression:"filters.opusNote"}})]},proxy:true}],null,false,2003999919)}):_vm._e(),_c('van-field',{attrs:{"placeholder":"最终价格将与平台商定","label":"作品价格","rules":[
              { required: true, message: '作品价格必填' }
            ],"required":""},model:{value:(_vm.filters.price),callback:function ($$v) {_vm.$set(_vm.filters, "price", $$v)},expression:"filters.price"}}),_c('van-cell',{attrs:{"title":"是否公开显示"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('van-switch',{attrs:{"size":"20px"},model:{value:(_vm.filters.openSearch),callback:function ($$v) {_vm.$set(_vm.filters, "openSearch", $$v)},expression:"filters.openSearch"}})]},proxy:true}],null,false,2437647929)})],2),_c('center',{staticStyle:{"margin-bottom":"24px"}},[_c('a-checkbox',{on:{"change":function($event){_vm.agreed = !_vm.agreed}}},[_vm._v("阅读并同意"),_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.$overlay.agreement(2)}}},[_vm._v("《服务条款》")])])],1),_c('van-button',{attrs:{"type":"info","disabled":!_vm.agreed,"loading":_vm.loading,"block":""}},[_vm._v("提交作品")])],1)],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }