<template>
  <div class="p-Setting">
    <div class="header">
      <van-icon name="arrow-left" size="20" @click="$router.push({ name: 'user' })" />
      <center>账号设置</center>
    </div>
    <van-cell-group>
      <van-cell title="绑定手机" :value="masked" is-link :to="{ name: 'setting-phone' }" />
      <van-cell title="更改账号密码" is-link :to="{ name: 'setting-password' }" />
    </van-cell-group>
    <van-cell-group>
      <van-cell title="用户协议" @click="$overlay.agreement(1)" is-link />
      <van-cell title="服务条款" @click="$overlay.agreement(2)" is-link />
    </van-cell-group>
    <van-cell-group>
      <van-cell title="退出登录" @click="$store.dispatch('Logout')" class="is-red">
        <template #right-icon>
          <van-icon class-prefix="icon-artalliance" name="logout" size="18" />
        </template>
      </van-cell>
    </van-cell-group>
    <art-widget-logo style="padding-top: 20px; padding-bottom: 20px" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'userInfo'
    ]),
    masked () {
      const phone = this.userInfo.mobile || ''

      return `${phone.slice(0, 3)}****${phone.slice(7, 11)}`
    }
  }
}
</script>

<style lang="less">
.p-Setting {
  min-height: 100vh;
  background-color: #F7F8FA;
  padding-bottom: @tabbar-height;

  .header {
    margin-bottom: 8px;
  }

  .van-cell-group {
    margin-bottom: 8px;
  }

  .is-red {
    color: #CC4E3F;
  }
}
</style>
