<template>
  <div class="p-Setting-Password">
    <div class="header">
      <van-icon name="arrow-left" size="20" @click="goBack" />
      <center>更改账号密码</center>
      <div class="top-right--text">
        <a @click="save">保存</a>
      </div>
    </div>
    <van-cell-group>
      <van-form @submit="onSubmit" ref="form">
        <van-field
          v-model="form.oldpassword"
          type="password"
          name="oldpassword"
          label="旧密码"
          placeholder="请输入旧密码"
          :rules="[
            { required: true, message: '请填写旧密码' }
          ]"
        />
        <van-field
          v-model="form.newpassword"
          type="password"
          name="newpassword"
          label="新密码"
          placeholder="请输入新密码"
          :rules="[
            { required: true, message: '请填写新密码' },
            { pattern: /\S{6}/, message: '新密码过短' },
          ]"
        />
        <van-field
          v-model="form.confirm"
          type="password"
          label="再次新密码"
          placeholder="请再次输入新密码"
          :rules="[
            { required: true, message: '请再次填写新密码' },
            { validator: val => this.form.newpassword === val, message: '两次密码不匹配' }
          ]"
        />
      </van-form>
    </van-cell-group>
  </div>
</template>

<script>
import request from '@/api/request'

export default {
  data () {
    return {
      form: {
        oldpassowrd: '',
        newpassword: '',
        confirm: ''
      }
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'setting' })
    },
    save () {
      const form = this.$refs.form

      if (form) {
        form.submit()
      }
    },
    onSubmit (values) {
      const {
        oldpassword,
        newpassword
      } = values

      request.setPassword({
        oldpassword,
        newpassword
      }).then(() => {
        this.$notify({
          type: 'success',
          message: '修改成功'
        })
        this.goBack()
      })
    }
  }
}
</script>

<style lang="less">
.p-Setting-Password {
  min-height: 100vh;
  background-color: #F7F8FA;
  padding-bottom: @tabbar-height;
}  
</style>