<template>
  <div class="p-Profile">
    <div class="header">
      <van-icon name="arrow-left" size="20" @click="$router.push({ name: 'user' })" />
      <center>我的资料</center>
      <div class="top-right--text">
        <a @click="save">保存</a>
      </div>
    </div>
    <van-cell-group>
      <van-cell title="头像" center>
        <template #right-icon>
          <art-widget-avatar v-model="form.avatar" oss-type="avatar" />
        </template>
      </van-cell>
    </van-cell-group>
    <van-cell-group>
      <van-field v-model="form.displayName" label="昵称" placeholder="请输入用户名" />
      <van-field
        v-model="form.userSay"
        label="个性签名"
        rows="4"
        type="textarea"
        maxlength="60"
        placeholder="请输入您的签名"
        show-word-limit
      />
      <!-- <van-field v-model="form.userLocation" label="常居地" placeholder="请输入用户名" /> -->
    </van-cell-group>
    <van-cell-group>
      <van-cell title="主页背景图" center>
        <template #right-icon>
          <art-widget-avatar v-model="form.backgroundUrl" oss-type="background" :round="false" />
        </template>
      </van-cell>
      <!-- <van-cell title="主页背景图" is-link /> -->
    </van-cell-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import request from '@/api/request'

import {
  DEFAULT_AVATAR,
  DEFAULT_BACKGROUND
} from '@/config/constant'

export default {
  data () {
    return {
      form: {
        avatar: '',
        displayName: '',
        backgroundUrl: '',
        // userLocation: '',
        userSay: ''
      }
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  watch: {
    userInfo: 'init'
  },
  methods: {
    init (userInfo = this.userInfo) {
      Object.keys(this.form).forEach(key => {
        if (userInfo[key]) {
          this.form[key] = userInfo[key]
        }
      })
    },
    afterRead (file) {
      request.upload(file.file).then(fileUrl => {
        this.fileList = [{ url: fileUrl }]
      })
    },
    save () {
      const {
        avatar,
        backgroundUrl,
        ...values
      } = this.form

      values.avatar = avatar === DEFAULT_AVATAR
        ? '' : avatar
      values.backgroundUrl = backgroundUrl === DEFAULT_BACKGROUND
        ? '' : backgroundUrl

      request.updateUser(values).then(() => {
        this.$notify({
          type: 'success',
          message: '保存成功'
        })
        this.$store.dispatch('$GetInfo')
      })
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="less">
.p-Profile {
  min-height: 100vh;
  background-color: #F7F8FA;

  .van-cell-group {
    margin-bottom: 5px;
  }
}
</style>