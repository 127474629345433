<template>
  <div class="p-UserId">
    <div class="topBackground">
      <van-image
        width="100%"
        height="215"
        :show-loading="false"
        fit="cover"
        :src="userInfo.backgroundUrl"
      />
    </div>
    <section class="topSection">
      <van-image
          round
          width="100"
          height="100"
          :src="userInfo.avatar === DEFAULT_AVATAR ? DEFAULT_AVATAR : userInfo.avatar + '!avatar'"
      />
      <div class="name">
        <h3>{{ userInfo.displayName }}</h3>
        <art-tag-role
            :type="userInfo.roleInfo"
            :on-click="handleClick"
        />
      </div>
    </section>
    <section class="number_box">
        <div class="text">
          <span>{{getRandomInRange(100,999)}}</span>
          <p>被收藏</p>
        </div>
      <div class="text">
        <span>{{getRandomInRange(130,999)}}</span>
        <p>浏览量</p>
      </div>
    </section>
    <div class="user-tab">
      <router-view />
      <van-tabbar route>
        <van-tabbar-item icon="friends-o"  replace :to="to('profile')" >我的资料</van-tabbar-item>
        <van-tabbar-item icon="chat-o"  replace :to="{ name: 'message' }" >消息通知</van-tabbar-item>
        <van-tabbar-item icon="setting-o"  replace :to="{ name: 'setting' }" >账号设置</van-tabbar-item>
      </van-tabbar>
    </div>
    <div style="padding-bottom: 50px;" v-if="userInfo.roleInfo == 0">
      <van-tabs v-model="tab_active">
        <van-tab title="收藏">
          <div class="context" style="padding: 20px;" v-if="ysjData.datas.length">
            <van-row gutter="15">
              <van-col
                  v-for="i in ysjData.datas"
                  @click="$router.push({ name: 'art', params: { id: i.opusId }})"
                  span="12"
                  :key="i.opusId"
              >
                <art-card-art
                    :name="i.opusTitle"
                    width="100%"
                    height="120"
                    :src="i.imgUrl"
                >
                  <span>{{ `${i.opusType} / ${i.width}x${i.height}cm / ${i.years}` }}</span>
                </art-card-art>
              </van-col>
            </van-row>
            <van-pagination v-model="ysjData.page" :page-count="ysjData.pageCount" mode="simple" @change="fetchData" />
          </div>
          <div v-else >
            <van-empty description="暂无收藏" />
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <div style="padding-bottom: 50px;" v-if="userInfo.roleInfo == 1">
      <van-tabs v-model="tab_active">
        <van-tab title="收藏">
          <div class="context" style="padding: 20px;" v-if="ysjData.datas.length">
            <van-row gutter="15">
              <van-col
                  v-for="i in ysjData.datas"
                  @click="$router.push({ name: 'art', params: { id: i.opusId }})"
                  span="12"
                  :key="i.opusId"
              >
                <art-card-art
                    :name="i.opusTitle"
                    width="100%"
                    height="120"
                    :src="i.imgUrl"
                >
                  <span>{{ `${i.opusType} / ${i.width}x${i.height}cm / ${i.years}` }}</span>
                </art-card-art>
              </van-col>
            </van-row>
            <van-pagination v-model="ysjData.page" :page-count="ysjData.pageCount" mode="simple" @change="fetchData" />
          </div>
          <div v-else >
            <van-empty description="暂无收藏" />
          </div>

        </van-tab>
        <van-tab title="作品">
          <div class="context" style="padding: 20px;"  v-if="ysjZpData.datas.length">
            <van-row gutter="15">
              <van-col
                  v-for="i in ysjZpData.datas"
                  @click="zPhandleClick(i)"
                  span="12"
                  :key="i.opusId"
              >
                <art-card-art
                    :name="i.opusTitle"
                    width="100%"
                    height="120"
                    :src="i.imgUrl"
                >
                  <span>{{ `${i.opusType} / ${i.width}x${i.height}cm / ${i.years}` }}</span>
                </art-card-art>
                <art-tag-art-verify :type="i.verfyStatus" />
              </van-col>
            </van-row>
            <van-pagination v-model="ysjZpData.page" :page-count="ysjZpData.pageCount" mode="simple" @change="fetchData" />
          </div>
          <div  v-else style="text-align: center">
            <van-empty description="暂无作品" />
            <van-button icon="plus" type="primary" @click="$router.push(to('uploaded'))">上传作品</van-button>
          </div>
        </van-tab>
        <van-tab title="履历">
          <div STYLE="margin-bottom: 30px;">
            <Resume></Resume>
<!--            <component :is="resume"></component>-->
<!--            <van-empty description="暂无履历" />-->
            <div style="text-align: center">
            <van-button style="margin-top: 20px" icon="plus" type="primary" @click="$router.push(to('resume'))">添加履历</van-button>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <div style="padding-bottom: 50px;" v-if="userInfo.roleInfo == 2">
      <van-tabs v-model="tab_active">
        <van-tab title="收藏">
          <div class="context" style="padding: 20px;" v-if="ysjData.datas.length">
            <van-row gutter="15">
              <van-col
                  v-for="i in ysjData.datas"
                  @click="$router.push({ name: 'art', params: { id: i.opusId }})"
                  span="12"
                  :key="i.opusId"
              >
                <art-card-art
                    :name="i.opusTitle"
                    width="100%"
                    height="120"
                    :src="i.imgUrl"
                >
                  <span>{{ `${i.opusType} / ${i.width}x${i.height}cm / ${i.years}` }}</span>
                </art-card-art>
              </van-col>
            </van-row>
            <van-pagination v-model="ysjData.page" :page-count="ysjData.pageCount" mode="simple" @change="fetchData" />
          </div>
          <div v-else >
            <van-empty description="暂无收藏" />
          </div>

        </van-tab>
        <van-tab title="订单">
          <div class="context" v-if="ysjZpData.datas.length">
            <van-row gutter="15">
              <van-col
                  v-for="i in ysjZpData.datas"
                  @click="zPhandleClick(i)"
                  span="12"
                  :key="i.opusId"
              >
                <art-card-art
                    :name="i.opusTitle"
                    width="100%"
                    height="120"
                    :src="i.imgUrl"
                >
                  <span>{{ `${i.opusType} / ${i.width}x${i.height}cm / ${i.years}` }}</span>
                </art-card-art>
                <art-tag-art-verify :type="i.verfyStatus" />
              </van-col>
            </van-row>
            <van-pagination v-model="ysjZpData.page" :page-count="ysjZpData.pageCount" mode="simple" @change="fetchData" />
          </div>
          <div  v-else style="text-align: center">
            <van-empty description="暂无订单" />
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <section
      v-for="(s, $index) in layouts"
      :key="$index"
      style="display: none"
    >
      <van-cell
        v-for="o in s"
        :title="o.title"
        :to="o.idRequired ? to(o.to) : { name: o.to }"
        :key="o.to"
        is-link
      >
        <template #icon>
          <van-icon class-prefix="icon-artalliance" :name="o.icon || o.to" size="20" />
        </template>
      </van-cell>
    </section>

    <van-overlay class="overlay-authenticate" :show="authVisible">
      <div class="clearfix" @click.stop>
        <van-form @submit="authenticate">
          <van-field
            v-model="authForm.name"
            name="name"
            label="姓名"
            placeholder="请输入姓名"
            :rules="[{ required: true, message: '请输入姓名' }]"
            required
          />
          <van-field
            name="sex"
            label="性别"
          >
            <template #input>
              <van-radio-group v-model="authForm.sex" direction="horizontal">
                <van-radio :name="0">男</van-radio>
                <van-radio :name="1">女</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            v-model="authForm.idCard"
            name="idCard"
            label="身份证号"
            placeholder="请输入身份证号"
            :rules="[{ required: true, message: '请输入身份证号' }]"
            required
          />
          <van-field
            v-model="authForm.phone"
            name="phone"
            label="联系电话"
            placeholder="请输入联系电话"
            :rules="[
              { required: true, message: '请输入联系电话' },
              { pattern: /^1[345789]\d{9}$/, message: '手机号码格式不正确' }
            ]"
            required
          />
          <van-field
            v-model="authForm.address"
            name="address"
            label="联系地址"
            placeholder="请输入联系地址"
            :rules="[{ required: true, message: '请输入联系地址' }]"
            required
          />
          <van-field
            name="authType"
            label="认证类型"
          >
            <template #input>
              <van-radio-group v-model="authForm.authType" direction="vertical">
                <van-radio :name="1">艺术家</van-radio>
                <van-radio :name="2">合伙人</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <template v-if="authForm.authType === 1">
            <van-field
              v-model="authForm.school"
              name="school"
              label="毕业学校"
              placeholder="请输入毕业学校"
            />
            <van-field
              v-model="authForm.opusType"
              name="opusType"
              label="作品类别"
              placeholder="请输入作品类别"
            />
            <van-cell
              class="upload"
              name="opusAuthsList"
              title="认证作品"
              required
              key="opusAuthsList"
            >
              <template #default>
                <span>{{ authForm.opusAuthsList.length }}/3</span>
              </template>
              <template #label>
                <art-widget-file-list
                  v-model="authForm.opusAuthsList"
                  oss-type="cert"
                  :max-count="3"
                />
              </template>
            </van-cell>
          </template>
          <template v-if="authForm.authType === 2">
            <van-field
              v-model="authForm.industry"
              name="industry"
              label="所属行业"
              placeholder="请输入所属行业"
            />
            <van-cell
              name="jobCertificate"
              title="职位证明或合作协议"
              required
              key="jobCertificate"
            >
              <template #label>
                <art-widget-avatar
                  width="80"
                  height="80"
                  oss-type="cert"
                  v-model="authForm.jobCertificate"
                  :round="false"
                />
              </template>
            </van-cell>
          </template>
          <van-button v-if="userInfo.roleInfo !== 3" class="pull-right" type="info" size="small">确&nbsp;定</van-button>
          <van-button class="pull-right" type="info" native-type="button" @click="authVisible = false" plain size="small">取&nbsp;消</van-button>
        </van-form>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import request from '@/api/request'
import { DEFAULT_AVATAR } from '@/config/constant'
import utils from '@/utils'
import {Resume} from "./tab-components";

export default {
  data () {
    return {
      tab_active:0,
      ysjData:{
        datas: [],
        page: 1,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
      },
      ysjZpData:{
        datas: [],
        page: 1,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0
      },
      authVisible: false,
      authForm: {
        name: '',
        sex: 0,
        idCard: '',
        phone: '',
        address: '',
        authType: 1,
        // if authType 1
        school: '',
        opusType: '',
        opusAuthsList: [],
        // if authType 2
        industry: '',
        jobCertificate: ''
      },
      DEFAULT_AVATAR
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ]),
    layouts() {
      const layouts = [
        [
          {
            title: '我的资料',
            to: 'profile',
            idRequired: true
          },
          {
            title: '我的收藏',
            to: 'favorites',
            icon: 'collection',
            idRequired: true
          },
          {
            title: '消息通知',
            to: 'message',
            icon: 'notif'
          },
          {
            title: '账号设置',
            to: 'setting'
          },
          {
            title: '关于不一致选',
            to: 'about'
          }
        ]
      ]

      if (this.userInfo.roleInfo === 1) {
        layouts.unshift(
          [
            {
              title: '上传作品',
              to: 'uploaded',
              icon: 'upload',
              idRequired: true
            },
            {
              title: '我的作品',
              to: 'my-works',
              icon: 'arts',
              idRequired: true
            },
            {
              title: '我的履历',
              to: 'resume',
              idRequired: true
            }
          ]
        )
      }

      if (this.userInfo.roleInfo === 2) {
        layouts.unshift(
          [
            {
              title: '成交清单',
              to: 'traded',
              icon: 'deal',
              idRequired: true
            }
          ]
        )
      }

      return layouts
    }
  },
  methods: {
    to (name) {
      return {
        name,
        params: { id: this.userInfo.id }
      }
    },

    getRandomInRange(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    userupload:function(){
      this.$router.push({
        name: 'userupload'
      })
    },

    zPfetchData () {
      request.getMyArtList({
        title: '',
        currPage: this.ysjZpData.page,
        pageSize: this.ysjZpData.pageSize
      }).then(res => {
        const {
          totalCount,
          totalPage,
          list = []
        } = res

        this.ysjZpData.totalCount = totalCount
        this.ysjZpData.pageCount = totalPage
        this.ysjZpData.datas = list

        utils.scrollTo(0)
      })
    },
    zPhandleClick (record) {
      const {
        verfyStatus,
        opusId
      } = record || {}

      if (opusId === undefined) {
        return
      }

      if (verfyStatus === 1) {
        return this.$router.push({
          name: 'art',
          params: { id: opusId }
        })
      }

      this.$router.push({
        name: 'uploadedId',
        params: {
          id: this.userInfo.id,
          opusId
        }
      })
    },

    fetchData () {
      request.getMyFavorite({
        title: '',
        currPage: this.ysjData.page,
        pageSize: this.ysjData.pageSize
      }).then(res => {
        const {
          totalCount,
          totalPage,
          list = []
        } = res

        this.ysjData.totalCount = totalCount
        this.ysjData.pageCount = totalPage
        this.ysjData.datas = list

        utils.scrollTo(0)
      })
    },
    handleClick () {
      switch (this.userInfo.roleInfo) {
        case 0: {
          this.authVisible = true
          break
        }
        case 3:
        case 4: {
          request.getAuth().then(res => {
            const {
              authType,
              ...values
            } = res && res.auth || {}

            if (authType === undefined) {
              return
            }

            if (Array.isArray(values.opusAuthsListFullPath)) {
              values.opusAuthsList = values.opusAuthsListFullPath
            }

            if (values.jobCertificateFullPath) {
              values.jobCertificate = values.jobCertificateFullPath
            }

            this.authForm.authType = authType
            this.$nextTick(() => {
              const form = {}

              for (const key in this.authForm) {
                if (values[key] !== undefined) {
                  form[key] = values[key]
                }
              }

              Object.assign(this.authForm, form)
              this.$nextTick(() => {
                this.authVisible = true
              })
            })
          })
          break
        }
      }
    },
    authenticate (values) {
      const {
        opusAuthsList,
        jobCertificate
      } = this.authForm

      if (values.authType === 1) {
        if (opusAuthsList.length === 0) {
          return this.$notify({
            type: 'danger',
            message: '请至少上传一张认证作品'
          })
        }

        values.opusAuthsList = opusAuthsList
      }

      if (values.authType === 2) {
        if (jobCertificate === '') {
          return this.$notify({
            type: 'danger',
            message: '请上传职业证明或合作协议'
          })
        }

        values.jobCertificate = jobCertificate
      }

      request.auth(values).then(() => {
        this.$notify({
          type: 'success',
          message: '已收到认证申请，请耐心等待'
        })

        // update authenticate status
        this.$store.dispatch('$GetInfo', () => {
          this.authVisible = false
        })
      })
    }
  },
  components: {Resume},
  created () {
    this.fetchData();
    this.zPfetchData();
  }
}
</script>

<style lang="less">
.user-tab .van-tabbar{ position: relative; margin-bottom: 20px; margin-top: -20px;
  .van-tabbar-item{ border-right: 1px #ddd solid;
  &:last-child{ border-right: none;}
  }
}
.van-tabs__line{ background-color: #01BCA5;}
.p-UserId {
  //padding: 32px 20px @tabbar-height + 20px;
  position: relative;
  min-height: 100vh;
  background-color: #F7F8FA;

  .topBackground {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    font-size: 0;

    &:after {
      content: '';
      background-color: rgba(0,0,0,.2);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .number_box{text-align: center; height: 64px; background: #ffffff; display: flex;
    .text{flex:1; padding-top: 5px;
      span{font-size: 20px; color: #333333;}
      p{ font-size: 12px; color: #999999;}
    }
  }

  section {
    margin-bottom: 20px;
    border-radius: 6px;
    overflow: hidden;

    .van-cell {
      //background-color: #fff;
      //padding: 12px 15px 12px 20px;

      & > .icon-artalliance {
        color: #B4B4B4;
        width: 32px;
      }
    }
  }

  .topSection {
    text-align: center;
    padding-top: 32px;
    .name{position: relative; z-index: 1; width: 100%; display: block;
      h3{font-size: 18px; color: #ffffff;}
    }
    //.van-cell {
    //  padding: 20px 15px 20px 20px;
    //}
    //
    //.van-cell__value {
    //  flex: initial;
    //
    //  & > span {
    //    color: #B4B4B4;
    //    font-size: 12px;
    //  }
    //}
    //
    //.van-image {
    //  display: inline-block;
    //  vertical-align: top;
    //
    //  & + div {
    //    padding-left: 16px;
    //    display: inline-block;
    //
    //    h3 {
    //      font-size: 18px;
    //      line-height: 24px;
    //      margin: 4px 0 6px;
    //    }
    //  }
    //}
  }
}

.p-tab-Resume {

  .block {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 5px;

    h3 {
      font-size: 16px;
      color: #040404;
      margin-bottom: 15px;
    }
    p {
      font-size: 14px;
      color: #454647;
      line-height: 1.4;
      margin-bottom: 10px;
    }
    .x-filter-html{
      img{ width: 98%;}
    }
  }

  .ant-timeline {
    padding: 8px 12px;
  }

  .ant-timeline-item {

    span {
      color: #B4B4B4;
      font-size: 12px;
      margin-bottom: 5px;
      display: inline-block;
    }

    p {
      font-size: 14px;
      color: #454647;
      line-height: 1.4;
    }
  }

  .ant-timeline-item-content {
    margin: 0 0 0 25px;
  }

  .ant-timeline-item-head-blue {
    height: 12px;
    width: 12px;
    color: #B7DFFF;
    border-color: #B7DFFF;
  }

  .ant-timeline-item-tail {
    border-left: 4px solid #F7F8FA;
  }
}

.overlay-authenticate {
  text-align: center;
  z-index: 9;

  &:before {
    content: '';
    height: 100%;
    width: 0;
    display: inline-block;
    vertical-align: middle;
  }

  .clearfix {
    width: 80%;
    padding: 20px 10px;
    text-align: left;
    background-color: #fff;
    display: inline-block;
    vertical-align: middle;

    .van-form > .van-button {
      margin-top: 20px;
      margin-left: 10px;
    }
  }

  .upload .van-cell__value {
    flex: initial;
  }

  .van-radio-group--vertical .van-radio:not(:last-child) {
    margin-bottom: 8px;
  }
}
</style>
