<template>
  <div class="p-Feedback">
    <div class="block">
      <van-field
        v-model="message"
        rows="4"
        type="textarea"
        maxlength="50"
        placeholder="请描述您遇到的问题，不少于15字"
        show-word-limit
      />
      <div>
        <van-uploader v-model="fileList" multiple :max-count="2" :after-read="afterRead" />
        <span>可上传3张图</span>
      </div>
    </div>
    <van-field
      v-model="phone"
      left-icon="phone"
      placeholder="请填写您的联系电话"
    />
    <div style="padding: 0 16px;">
      <van-button type="info" block>提交</van-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      message: '',
      phone: '',
      fileList: []
    }
  },
  methods: {
    afterRead () {}
  }
}
</script>

<style lang="less">
.p-Feedback {
  
  .block {
    background-color: #fff;
    padding: 16px 16px 8px 16px;
    margin-bottom: 12px;

    .van-cell {
      margin-bottom: 12px;
      background-color: #fafafa;

      &::after {
        content: initial;
      }
    }

    .van-uploader {

      + span {
        color: #8a8a8a;
        font-size: 12px;
        vertical-align: bottom;
        position: relative;
        bottom: 8px;
      }
    }

    + .van-cell {
      margin-bottom: 36px;
    }
  }
}
</style>