<template>
  <div class="p-About">
    <div class="header">
      <van-icon name="arrow-left" size="20" @click="$router.push({ name: 'user' })" />
      <center>关于不致一选</center>
    </div>
    <article v-filter-html="localData"></article>
    <art-widget-logo style="padding-top: 20px; padding-bottom: 20px" />
  </div>
</template>

<script>
import request from '@/api/request'

export default {
  data () {
    return {
      localData: ''
    }
  },
  methods: {
    fetchData () {
      request.getArgeement({
        aType: 3
      }).then(({ content = '' }) => {
        this.localData = content
      })
    }
  },
  mounted () {
    this.fetchData()
  }
}
</script>

<style lang="less">
.p-About {
  min-height: 100vh;
  background-color: #F7F8FA;
  padding-bottom: @tabbar-height;

  article {
    padding: 32px 24px;
  }
}
</style>