<template>
  <div class="p-Setting-Phone">
    <div class="header">
      <van-icon name="arrow-left" size="20" @click="goBack" />
      <center>绑定手机</center>
      <div class="top-right--text">
        <a @click="save">保存</a>
      </div>
    </div>
    <van-cell-group>
      <van-form @submit="onSubmit" ref="form">
        <van-field
          v-model="form.oldmobile"
          name="oldmobile"
          label="旧手机号"
          placeholder="请填写旧手机号"
          :rules="[
            { required: true, message: '请填写旧手机号' },
            { pattern: /^1[345789]\d{9}$/, message: '手机号码格式不正确' }
          ]"
        />
        <van-field
          v-model="form.newmobile"
          name="newmobile"
          label="新手机号"
          placeholder="请填写新手机号"
          :rules="[
            { required: true, message: '请填写新手机号' },
            { pattern: /^1[345789]\d{9}$/, message: '手机号码格式不正确' }
          ]"
        />
        <art-field-captcha
          v-model="form.code"
          label="验证码"
          size="normal"
          @start="getCaptcha"
        />
      </van-form>
    </van-cell-group>
  </div>
</template>

<script>
import request from '@/api/request'

export default {
  data () {
    return {
      form: {
        oldmobile: '',
        newmobile: '',
        code: ''
      }
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'setting' })
    },
    save () {
      const form = this.$refs.form

      if (form) {
        form.submit()
      }
    },
    onSubmit (values) {
      request.setPhone(values)
        .then(() => {
          this.$notify({
            type: 'success',
            message: '修改成功'
          })
          this.goBack()
        })
    },
    getCaptcha (start) {
      const form = this.$refs.form

      if (form) {
        form.validate('newmobile')
          .then(() => {
            const mobileNumber = this.form.newmobile

            request.getCaptchaBeforeSetPhone({ mobileNumber })
              .then(() => start())
          })
      }
    }
  }
}
</script>

<style lang="less">
.p-Setting-Phone {
  min-height: 100vh;
  background-color: #F7F8FA;
  padding-bottom: @tabbar-height;
}  
</style>