<template>
  <div class="p-UserId">
      <div class="clearfix" @click.stop>
        <van-form @submit="authenticate">
          <van-field
            v-model="authForm.name"
            name="name"
            label="姓名"
            placeholder="请输入姓名"
            :rules="[{ required: true, message: '请输入姓名' }]"
            required
          />
          <van-field
            name="sex"
            label="性别"
          >
            <template #input>
              <van-radio-group v-model="authForm.sex" direction="horizontal">
                <van-radio :name="0">男</van-radio>
                <van-radio :name="1">女</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            v-model="authForm.idCard"
            name="idCard"
            label="身份证号"
            placeholder="请输入身份证号"
            :rules="[{ required: true, message: '请输入身份证号' }]"
            required
          />
          <van-field
            v-model="authForm.phone"
            name="phone"
            label="联系电话"
            placeholder="请输入联系电话"
            :rules="[
              { required: true, message: '请输入联系电话' },
              { pattern: /^1[345789]\d{9}$/, message: '手机号码格式不正确' }
            ]"
            required
          />
          <van-field
            v-model="authForm.address"
            name="address"
            label="联系地址"
            placeholder="请输入联系地址"
            :rules="[{ required: true, message: '请输入联系地址' }]"
            required
          />
          <van-field
            name="authType"
            label="认证类型"
          >
            <template #input>
              <van-radio-group v-model="authForm.authType" direction="vertical">
                <van-radio :name="1">艺术家</van-radio>
                <van-radio :name="2">合伙人</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <template v-if="authForm.authType === 1">
            <van-field
              v-model="authForm.school"
              name="school"
              label="毕业学校"
              placeholder="请输入毕业学校"
            />
            <van-field
              v-model="authForm.opusType"
              name="opusType"
              label="作品类别"
              placeholder="请输入作品类别"
            />
            <van-cell
              class="upload"
              name="opusAuthsList"
              title="认证作品"
              required
              key="opusAuthsList"
            >
              <template #default>
                <span>{{ authForm.opusAuthsList.length }}/3</span>
              </template>
              <template #label>
                <art-widget-file-list
                  v-model="authForm.opusAuthsList"
                  oss-type="cert"
                  :max-count="3"
                />
              </template>
            </van-cell>
          </template>
          <template v-if="authForm.authType === 2">
            <van-field
              v-model="authForm.industry"
              name="industry"
              label="所属行业"
              placeholder="请输入所属行业"
            />
            <van-cell
              name="jobCertificate"
              title="职位证明或合作协议"
              required
              key="jobCertificate"
            >
              <template #label>
                <art-widget-avatar
                  width="80"
                  height="80"
                  oss-type="cert"
                  v-model="authForm.jobCertificate"
                  :round="false"
                />
              </template>
            </van-cell>
          </template>
          <van-button v-if="userInfo.roleInfo !== 3" class="pull-right" type="info" size="small">确&nbsp;定</van-button>
        </van-form>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import request from '@/api/request'
import { DEFAULT_AVATAR } from '@/config/constant'

export default {
  data () {
    return {
      authVisible: true,
      authForm: {
        name: '',
        sex: 0,
        idCard: '',
        phone: '',
        address: '',
        authType: 1,
        // if authType 1
        school: '',
        opusType: '',
        opusAuthsList: [],
        // if authType 2
        industry: '',
        jobCertificate: ''
      },
      DEFAULT_AVATAR
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ]),
    layouts() {
      const layouts = [
        [
          {
            title: '我的资料',
            to: 'profile',
            idRequired: true
          },
          {
            title: '我的收藏',
            to: 'favorites',
            icon: 'collection',
            idRequired: true
          },
          {
            title: '消息通知',
            to: 'message',
            icon: 'notif'
          },
          {
            title: '账号设置',
            to: 'setting'
          },
          {
            title: '关于不一致选',
            to: 'about'
          }
        ]
      ]

      if (this.userInfo.roleInfo === 1) {
        layouts.unshift(
          [
            {
              title: '上传作品',
              to: 'uploaded',
              icon: 'upload',
              idRequired: true
            },
            {
              title: '我的作品',
              to: 'my-works',
              icon: 'arts',
              idRequired: true
            },
            {
              title: '我的履历',
              to: 'resume',
              idRequired: true
            }
          ]
        )
      }

      if (this.userInfo.roleInfo === 2) {
        layouts.unshift(
          [
            {
              title: '成交清单',
              to: 'traded',
              icon: 'deal',
              idRequired: true
            }
          ]
        )
      }

      return layouts
    }
  },
  methods: {
    to (name) {
      return {
        name,
        params: { id: this.userInfo.id }
      }
    },
    handleClick () {
      switch (this.userInfo.roleInfo) {
        case 0: {
          this.authVisible = true
          break
        }
        case 3:
        case 4: {
          request.getAuth().then(res => {
            const {
              authType,
              ...values
            } = res && res.auth || {}

            if (authType === undefined) {
              return
            }

            if (Array.isArray(values.opusAuthsListFullPath)) {
              values.opusAuthsList = values.opusAuthsListFullPath
            }

            if (values.jobCertificateFullPath) {
              values.jobCertificate = values.jobCertificateFullPath
            }

            this.authForm.authType = authType
            this.$nextTick(() => {
              const form = {}

              for (const key in this.authForm) {
                if (values[key] !== undefined) {
                  form[key] = values[key]
                }
              }

              Object.assign(this.authForm, form)
              this.$nextTick(() => {
                this.authVisible = true
              })
            })
          })
          break
        }
      }
    },
    authenticate (values) {
      const {
        opusAuthsList,
        jobCertificate
      } = this.authForm

      if (values.authType === 1) {
        if (opusAuthsList.length === 0) {
          return this.$notify({
            type: 'danger',
            message: '请至少上传一张认证作品'
          })
        }

        values.opusAuthsList = opusAuthsList
      }

      if (values.authType === 2) {
        if (jobCertificate === '') {
          return this.$notify({
            type: 'danger',
            message: '请上传职业证明或合作协议'
          })
        }

        values.jobCertificate = jobCertificate
      }

      request.auth(values).then(() => {
        this.$notify({
          type: 'success',
          message: '已收到认证申请，请耐心等待'
        })

        // update authenticate status
        this.$store.dispatch('$GetInfo', () => {
          this.authVisible = false
        })
      })
    }
  }
}
</script>

<style lang="less">
.p-UserId {
  padding: 70px 20px @tabbar-height + 20px;
  position: relative;
  min-height: 100vh;
  background-color: #F7F8FA;

  .topBackground {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    font-size: 0;

    &:after {
      content: '';
      background-color: rgba(0,0,0,.2);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  section {
    margin-bottom: 20px;
    border-radius: 6px;
    overflow: hidden;

    .van-cell {
      background-color: #fff;
      padding: 12px 15px 12px 20px;

      & > .icon-artalliance {
        color: #B4B4B4;
        width: 32px;
      }
    }
  }

  .topSection {

    .van-cell {
      padding: 20px 15px 20px 20px;
    }

    .van-cell__value {
      flex: initial;

      & > span {
        color: #B4B4B4;
        font-size: 12px;
      }
    }

    .van-image {
      display: inline-block;
      vertical-align: top;

      & + div {
        padding-left: 16px;
        display: inline-block;

        h3 {
          font-size: 18px;
          line-height: 24px;
          margin: 4px 0 6px;
        }
      }
    }
  }
}

.overlay-authenticate {
  text-align: center;
  z-index: 9;

  &:before {
    content: '';
    height: 100%;
    width: 0;
    display: inline-block;
    vertical-align: middle;
  }

  .clearfix {
    width: 80%;
    padding: 20px 10px;
    text-align: left;
    background-color: #fff;
    display: inline-block;
    vertical-align: middle;

    .van-form > .van-button {
      margin-top: 20px;
      margin-left: 10px;
    }
  }

  .upload .van-cell__value {
    flex: initial;
  }

  .van-radio-group--vertical .van-radio:not(:last-child) {
    margin-bottom: 8px;
  }
}
</style>
